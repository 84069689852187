import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SigninComponent } from './common/signin/signin.component';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { MobileNoComponent } from './common/mobile-no/mobile-no.component';
import { OtpComponent } from './common/otp/otp.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'signin',
    pathMatch: 'full',
  },
  {
    path: 'signin',
    component: SigninComponent,
  },
  {
    path: 'mobile',
    component: MobileNoComponent,
  },
  {
    path: 'otp',
    component: OtpComponent,
  },
  {
    path: 'hubAdmin',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./hubadmin/hubadmin.module').then(m => m.HubadminModule)
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true
  })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiConfiguration } from 'src/app/services/http/api-configuration';
import { ApiService } from 'src/app/services/http/api.service';
import { ToastClientService } from 'src/app/services/toast/toast-client.service';

@Component({
  selector: 'app-mobile-no',
  templateUrl: './mobile-no.component.html',
  styleUrls: ['./mobile-no.component.scss']
})
export class MobileNoComponent implements OnInit {
  loginForm;
  submitted : boolean = false;
  @Output() isMobileNumberSubmitted: EventEmitter<boolean> = new EventEmitter;

  constructor(
    private apiService: ApiService,
    private apiConfig: ApiConfiguration,
    private router: Router,
    private formBuilder: FormBuilder,
    private toast: ToastClientService,

  ) {
    this.loginForm = formBuilder.group({
      mobile_no: ['', Validators.compose([
        Validators.required,
        Validators.minLength(10),Validators.maxLength(10),
      ])],
    });
  }

  login(user) {
    this.submitted = true;
    console.log(this.loginForm)
    if(this.loginForm.invalid){
      return
    }
    let payload = {
      program_id:1,
      mobile_no : user.mobile_no
    }

    // this.apiService.post(this.apiConfig.login , payload).subscribe((res : any )=>{
    //   console.log(res)
    //   localStorage.setItem("user_detail", JSON.stringify(res.body.data))
    //   this.isMobileNumberSubmitted.emit(true);
    // },(err : any)=>{
    //   console.log(err)
    // })  
  }

  get f() { return this.loginForm.controls; }


  ngOnInit() {
  }

}
